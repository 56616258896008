import React from 'react'
import Layout from '../layouts'
import { graphql } from 'gatsby'
import WebriQForm from '@webriq/gatsby-webriq-form'
// import ShopCategory from "../components/ecwid/category"

class ContactUs extends React.Component {
	componentDidMount() {
		if (window) {
			const script = document.createElement('script')
			script.type = 'text/javascript'
			script.src = `https://forms.webriq.com/js/initForms`
			const headScript = document.getElementsByTagName('script')[0]
			headScript.parentNode.insertBefore(script, headScript)
		}
	}
	render() {
		let params = this.props.location.pathname
		return (
			<Layout
				pathname={params}
				head={{
					name:
						'Teak Lumber Price Quote Request -- Rough-Sawn -- Custom Milled -- Large Quantities',
					meta: {
						description:
							'Teak Lumber Price Quote Request -- Rough-Sawn -- Custom Milled -- Large Quantities Please use this form to request price & shipping quotes for rough-sawn and/or custom-milled teak lumber.',
					},
				}}
			>
				<div className="container py-5">
					<div className="row">
						{/*<div className="col-md-4">
							<h2 className="section-heading">Categories</h2>
							<ShopCategory/>
						</div>*/}
						<div className="col-md-8 shop-items mx-auto">
							<div className="page-content">
								<h4 className="section-heading page-mt">
									Teak Lumber Price Quote Request -- Rough-Sawn -- Custom Milled
									-- Large Quantities
								</h4>
								<p>
									Please use this form to request price & shipping quotes for
									rough-sawn and/or custom-milled teak lumber. We can also
									provide pricing for quarter-sawn teak lumber in larger
									quantities for boat decking or similar requirements.
								</p>
								<p>
									Lumber shipped to a Florida address will be subject to sales
									tax. Truck freight shipments to a residence are higher than if
									shipped to a business with a loading dock and/or lift truck.
								</p>
								<WebriQForm
									name="Request a Quote"
									data-thankyou-url="/thank-you"
									data-form-id="5e65929bd9107d747c640114"
								>
									<div className="form-group">
										<label>Your Name:</label>
										<input
											className="form-control"
											type="text"
											name="Name"
											required=""
										/>
									</div>

									<div className="form-group">
										<label>Your Email Address</label>
										<input
											className="form-control"
											type="text"
											name="Email"
											required=""
										/>
									</div>

									<div className="form-group">
										<label>Contact Phone Number</label>
										<input
											className="form-control"
											type="number"
											name="Phone Number"
											required=""
										/>
									</div>

									<div className="form-group">
										<label>Ship-To Location</label>
										<br />
										<input
											className="mr-1"
											type="radio"
											name="Ship-To Location"
											value="Residence, Retail Shop, Dr's Office, etc."
											required="required"
										/>
										Residence, Retail Shop, Dr's Office, etc.
										<br />
										<input
											className="mr-1"
											type="radio"
											name="Ship-To Location"
											value="Business With Loading Dock Or Lift Truck "
											required="required"
										/>
										Business With Loading Dock Or Lift Truck
										<br />
									</div>

									<div className="form-group">
										<label>Ship-To Zip Code</label>
										<input
											className="form-control"
											type="number"
											name="Zip Code"
											required=""
										/>
									</div>

									<div className="form-group">
										<label>
											Do you want rough-sawn lumber (board dimensions will vary)
											or boards milled to exact specs?
										</label>
										<br />
										<input
											className="mr-1"
											type="radio"
											name="Do you want rough-sawn lumber (board dimensions will vary) or boards milled to exact specs?"
											value=" Rough-Sawn "
											required="required"
										/>{' '}
										Rough-Sawn
										<br />
										<input
											className="mr-1"
											type="radio"
											name="Do you want rough-sawn lumber (board dimensions will vary) or boards milled to exact specs?"
											value="Business With Loading Dock "
											required="required"
										/>
										Business With Loading Dock Or Lift Truck
										<br />
									</div>

									<div className="form-group">
										<label>How Thick?</label>
										<br />
										<input
											className="mr-1"
											type="radio"
											name="How Thick?"
											value="Residence, Retail Shop, Dr's Office, etc."
											required="required"
										/>{' '}
										Rough-Sawn
										<br />
										<input
											className="mr-1"
											type="radio"
											name="How Thick?"
											value="Business With Loading Dock Or Lift Truck "
											required="required"
										/>
										Business With Loading Dock Or Lift Truck
										<br />
										<input
											className="mr-1"
											type="radio"
											name="How Thick?"
											value="Other (Please specify below)"
											required="required"
										/>
										Other (Please specify below)
									</div>

									<div className="form-group">
										<label>
											Lumber Thickness, Width, Length & Quantities Required
										</label>
										<textarea
											className="form-control"
											rows="4"
											name="Lumber Thickness, Width, Length & Quantities Required"
										/>
									</div>

									<div className="form-group">
										<label>Additional Comments, Questions, Information</label>
										<textarea
											className="form-control"
											rows="4"
											name="Additional Comments, Questions, Information"
										/>
									</div>

									<div className="webriq-recaptcha mb-3" />
									<div className="form-group">
										<button
											className="btn btn-primary btn-block blue-btn"
											type="submit"
										>
											Submit
										</button>
									</div>
								</WebriQForm>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default ContactUs

export const categorynodeQuery = graphql`
	query {
		allStrapiCategories(sort: { fields: id, order: ASC }) {
			edges {
				node {
					id
					name
					url
					subcategories {
						name
						url
					}
				}
			}
		}
	}
`
